import React, { useEffect } from 'react';
import '../App.css';
import { Button } from './Button.js';
import './HeroSection.css';
import Slideshow from './Slideshow';

function HeroSection() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className='hero-container'>
        <Slideshow />
        <h1>Welcome!</h1>
        <p>Steph and Neal are getting Married!</p>
        <div className='hero-btns'>
            <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' path='/Accommodation'>
              Need a place to stay?
            </Button>
            <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large' path='/RSVP'>
                RSVP
            </Button>
        </div>
    </div>
  )
}

export default HeroSection