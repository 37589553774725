import React, { useEffect } from 'react';
import '../App.css';
import './DetailHeader.css';

function DetailHeader() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="hero-container">
            <div className='hero-container-background'>
                <h1>Details for the day</h1>
                <p>We're booked in for October 31st 2024</p>
                <p>Dress code is Smart - not fancy dress despite it being Hallowe'en!</p>
            </div>
        </div>
  )
}

export default DetailHeader