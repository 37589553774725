import React, {useState, useEffect} from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [click,setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960){
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(()=>{
        showButton();
        window.scrollTo(0, 0);
    },[]);

    window.addEventListener('resize',showButton);

  return (
    <>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    S&N <i class="fa-regular fa-heart"></i>
                </Link>  
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Details' className='nav-links' onClick={closeMobileMenu}>
                            Details of the Day
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Accommodation' className='nav-links' onClick={closeMobileMenu}>
                            Local Accommodation
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/RSVP' className='nav-links-mobile' onClick={closeMobileMenu}>
                            RSVP
                        </Link>
                    </li>
                </ul>
                {button && <Button buttonStyle='btn--outline' path='/RSVP'>RSVP</Button>}
            </div>
        </nav>
    </>
  )
}

export default Navbar