import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

  return (
    <div className='footer-container' id='footer'>
        <section className='footer-links'>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>Venue</h2>
                    <Link to='https://fleece-inn.com/' target='_blank'>The Fleece</Link>
                    <Link to='https://www.google.com/maps/place/The+Fleece+Countryside+Inn/@53.6762199,-1.9288992,15z/data=!4m2!3m1!1s0x0:0xd82cf259d1954e65?sa=X&ved=2ahUKEwjc4enWh4eBAxVkT0EAHWyZA1cQ_BJ6BAhREAA&ved=2ahUKEwjc4enWh4eBAxVkT0EAHWyZA1cQ_BJ6BAhaEAc' target='_blank'>Where is it?</Link>
                </div>
                <div className='footer-link-items'>
                    <h2>Info</h2>
                    <Link to='/Details'>Details of the Day</Link>
                    <Link to='/Accommodation'>Accommodation Links</Link>
                    <Link to='/Wishlist'>Gift Wishlist</Link>
                </div>
                <div className='footer-link-items'>
                    <h2>Social Media</h2>
                    <Link to='mailto:collinswakeford@gmail.com'>Contact Us</Link>
                    <Link to='https://www.instagram.com/explore/tags/StephandNeal/' target='_blank'>Add your pics to our Instagram tag!</Link>
                </div>
            </div>
        </section>
        <section className='social-media'>
            <div className='social-media-wrap'>
                <small className='website-rights'>Neal Wakeford © 2023</small>
                <div className='footer-logo'>
                    <Link className='social-logo' to='/'>
                        S&N <i class="fa-regular fa-heart"></i>
                    </Link>
                </div>
                <div className='social-icons'>
                    <Link className='social-icon-link instagram' to='https://www.instagram.com/explore/tags/StephandNeal/' target='_blank' aria-label='Instagram Tag'>
                        <i className='fab fa-instagram'></i>
                    </Link>
                    <Link className='social-icon-link envelope' to='mailto:collinswakeford@gmail.com' aria-label='Contact Us'>
                        <i className='fa-regular fa-envelope'></i>
                    </Link>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Footer