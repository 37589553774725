import React from 'react';
import './App.css';
import Navbar from './components/Navbar.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Accommodation from './components/pages/Accommodation';
import Details from './components/pages/Details';
import RSVP from './components/pages/RSVP';
import Taxis from './components/pages/Taxis';
import Wishlist from './components/pages/Wishlist';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route path = '/' exact element={<Home/>}/>
        <Route path = '/Details' exact element={<Details/>}/>
        <Route path = '/Accommodation' exact element={<Accommodation/>}/>
        <Route path = '/RSVP' exact element={<RSVP/>}/>
        <Route path = '/Taxis' exact element={<Taxis/>}/>
        <Route path = '/Wishlist' exact element={<Wishlist/>}/>
      </Routes>
    </Router>
      </>
  );
}

export default App;
