import React, {useState, useRef}  from "react";
import '../../App.css';
import './RSVP.css';
import Footer from "../Footer";

export default function RSVP() {
    const [formData, setFormData] = useState({name: "",email: "",coming: "",music: "",dietary: "",notes: ""});
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmit] = useState(false);
    const scriptUrl = "https://script.google.com/macros/s/AKfycbyPos6xD8SVmGMjWDnsKZifOxpAvYoknJfSmOqz3Eg9JhUM2q8hmW_jEhhoI31Jv6-0/exec"
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      setLoading(true)

      fetch(scriptUrl, {
      method: 'POST', 
      //body: formData,
      body: new FormData(formRef.current),

        }).then(res => {
                setSubmit(true)
                setLoading(false)
            })
            .catch(err => console.log(err))
  };


    return (
        <>
            <div className='hero-container-background' >
                <h2>Let us know if you can make it!</h2>
                <div className="form-div">
                    <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                    <div className="form-style">
                        <input type="text"  name="name" placeholder='Your Name(s)' value={formData.name} onChange={handleChange}/>
                    </div>  
                    <div className="form-style">
                        <input type="email" name="email" placeholder='Your Email' value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="form-style">
                        <select name="coming" value={formData.coming} onChange={handleChange}>
                            <option  value="Yes">We'll be There!</option>
                            <option  value="No">Sorry, we can't make it.</option>
                        </select>
                    </div>
                    <div className="form-style">
                        <textarea id="music" name="music" placeholder='Any song requests?' value={formData.music} onChange={handleChange}/>
                    </div>
                    <div className="form-style">
                        <textarea id="dietary" name="dietary" placeholder='Dietary requirements / Allergies' value={formData.dietary} onChange={handleChange}/>
                    </div>
                    <div className="form-style">
                        <textarea id="notes" name="notes" placeholder='Anything else you want to tell us?' value={formData.notes} onChange={handleChange}/>
                    </div>
                    <div className="input-style">
                        <input type="submit" value={loading ? "Loading..." : submitted ? "Done!" : "Submit"} />
                    </div> 
                    </form>
                </div>
            </div>
            <Footer />
        </>);
}