import React from "react";
import '../../App.css';
import '../HeroSection.css';
import Footer from "../Footer";
import Cards from "../AccomCards";

export default function Accommodation() {
    return (
    <>
    <Cards />
    <Footer />
    </>);
}