import React from "react";
import '../../App.css';
import '../HeroSection.css';
import Footer from "../Footer";

export default function Taxis() {
    return (
    <>
        <div className='hero-container-background'>
            <h1>Local Taxi Firms</h1>
            <table>
                <tr><th>A1/GW</th><td>01422 835050</td></tr>
                <tr><th>Ripponden Cars</th><td>01422 822222</td></tr>
                <tr><th>Crossleys Cars</th><td>01422 202020</td></tr>
            </table>
        </div>
        <Footer />
    </>);
}