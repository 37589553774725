import React from "react";
import { useEffect, useState } from "react";
import './HeroSection.css';

export default function Slideshow() {
  const [photo, setPhoto] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

const isMobile = width <= 768; 

  useEffect(() => {
    const interval = setInterval(() => {
      change();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [photo]);

  const change = () => {
    if (isMobile){
      if (photo === 13) {
        setPhoto(1);
        return;
      }
    }
    else {
        if (photo === 12) {
        setPhoto(1);
        return;
      }
    }

    setPhoto((prev) => prev + 1);
  };

  const returnPhotoURL = () => {
    switch (photo) {
        case 1:
            return "../bg/bg1.png";
        case 2:
            return "../bg/bg2.png";
        case 3:
            return "../bg/bg3.png";
        case 4:
            return "../bg/bg4.png";
        case 5:
            return "../bg/bg5.png";
        case 6:
            return "../bg/bg7.png";
        case 7:
            return "../bg/bg8.png";
        case 8:
            return "../bg/bg9.png";
        case 9:
            return "../bg/bg10.png";
        case 10:
            return "../bg/bg11.png";
        case 11:
            return "../bg/bg12.png";
        case 12:
            return "../bg/bg13.png";
      default:
        return null;
    }
  };

  const returnMobilePhotoURL = () => {
    switch (photo) {
        case 1:
            return "../mobbg/bg1.png";
        case 2:
            return "../mobbg/bg1-1.png";
        case 3:
            return "../mobbg/bg2.png";
        case 4:
            return "../mobbg/bg4.png";
        case 5:
            return "../bg/bg5.png";
        case 6:
            return "../mobbg/bg7.png";
        case 7:
            return "../bg/bg8.png";
        case 8:
            return "../mobbg/bg9.png";
        case 9:
            return "../bg/bg10.png";
        case 10:
            return "../bg/bg11.png";
        case 11:
            return "../bg/bg12.png";
        case 12:
            return "../bg/bg13.png";
        case 13:
          return "../bg/bg3.png";
      default:
        return null;
    }
  };

  if (isMobile) {
    return (
      <div 
        style={{
          backgroundImage: `url(${returnMobilePhotoURL()})`,  
          position:"fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justify: "center",
          align: "center",
          fit: "cover",
          zIndex: "-1"
        }}
      ></div>
    );
  }
  else 
  {
    return (
      <div 
        style={{
          backgroundImage: `url(${returnPhotoURL()})`,  
          position:"fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justify: "center",
          align: "center",
          fit: "cover",
          zIndex: "-1"
        }}
      ></div>
    );
  }
}