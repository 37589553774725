import React from 'react'
import './Footer.css'
import './Cards.css';

function EventDetails() {
  return (
    <div className='cards'>
        <h1>Timeline of Events</h1>
        <div className='cards__container'>
            <div className='events'>
                <div className='event-wrapper'>
                    <div className='event-items'><p>12:30 - Ceremony Guests Arrive</p></div>
                    <div className='event-items'><p>13:00 - Ceremony</p></div>
                    <div className='event-items'><p>13:30 - Arrival Drinks</p></div>
                    <div className='event-items'><p>13:45 - Canapés</p></div>
                    <div className='event-items'><p>15:00 - Speeches</p></div>
                    <div className='event-items'><p>15:15 - Wedding Breakfast</p></div>
                    <div className='event-items'><p>19:00 - Evening Guests Arrive</p></div>
                    <div className='event-items'><p>19:30 - Cake Cutting</p></div>
                    <div className='event-items'><p>20:30 - Evening Buffet</p></div>
                    <div className='event-items'><p>23:30 - Last Orders!</p></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EventDetails