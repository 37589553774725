import React from "react";
import '../../App.css';
import '../HeroSection.css';
import Footer from "../Footer";

export default function Wishlist() {
    return (
    <>
        <div className='hero-container-background'>
            <h1>Gift Wishlist</h1>
            <p>We've already got our house (mostly) in order.</p>
            <p>Really we'd just appreciate some funds towards our Honeymoon!</p>
        </div>
        <Footer />
    </>);
}