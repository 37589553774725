import React from "react";
import '../../App.css';
import Footer from "../Footer";
import DetailHeader from '../DetailHeader.js';
import EventDetails from "../EventDetails.js";

export default function Details() {
    return (
    <>
        <DetailHeader />
        <EventDetails />
        <Footer />
    </>);
}