import React from 'react'
import CardItem from './CardItem'
import './Footer.css'
import './Cards.css';
import { Link } from 'react-router-dom';

function AccomCards() {
  return (
    <div className='cards'>
        <h1>Some Local Places to Stay</h1>
        <div className='cards__container'>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardItem src="https://www.premierinn.com/content/dam/pi/websites/hotelimages/gb/en/H/HALBRO/xHALBRO,P201.jpg.pagespeed.ic.x7K_BzPDgX.webp" text='Halifax Town Centre Premier Inn' label='10 Minutes in a Taxi' path='https://www.premierinn.com/gb/en/hotels/england/west-yorkshire/halifax/halifax-town-centre.html' target='_blank'/>
                    <CardItem src="../images/accom/Lion.jpg" text="The Lion" label="Nearby" path="https://therippondenlion.co.uk/" />
                    <CardItem src="../images/accom/Bridge.png" text="Over the Bridge B&B" label="Nearby" path="https://www.over-the-bridge.com/" />
                </ul>
            </div>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardItem src="https://lirp.cdn-website.com/32a2c05c/dms3rep/multi/opt/IMG_6836-605e7cf4-1920w.JPG" text='High Stones Holiday Cottages' label='20 Minutes in a Taxi' path='https://www.highstones.co.uk/' target='_blank'/>
                    <CardItem src="../images/accom/thurst_house_farm.jpg" text="Thurst House" label="10 Minutes in a Taxi" path="http://www.thursthousefarm.co.uk/" />
                    <CardItem src="../images/accom/fortune.jpg" text="Fortune Hotel" label="15 Minutes in a Taxi" path="https://www.bestwestern.co.uk/hotels/fortune-huddersfield-sure-hotel-collection-by-best-western-84347" />
                </ul>
            </div>
        </div>
        <h1>Other Sites to check</h1>
        <div className='cards__container'>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='accom-link-items'>
                        <Link to='https://www.visitcalderdale.com/places-to-stay/'>Visit Calderdale</Link>
                    </div>
                    <div className='accom-link-items'>
                        <Link to='https://www.booking.com' target='_blank'>Booking.com</Link>
                    </div>
                    <div className='accom-link-items'>
                        <Link to='https://uk.hotels.com' target='_blank'>Hotels.com</Link>
                    </div>
                    <div className='accom-link-items'>
                        <Link to='https://www.airbnb.co.uk/s/Barkisland--Halifax-HX4-0DJ/homes?adults=1&place_id=ChIJv_6gTbPDe0gRssdDGzR9RiA&refinement_paths%5B%5D=%2Fhomes&tab_id=home_tab&query=Barkisland%2C%20Halifax%20HX4%200DJ&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2023-09-01&monthly_length=3&price_filter_input_type=2&price_filter_num_nights=5&channel=EXPLORE&date_picker_type=calendar&checkin=2024-10-31&checkout=2024-11-01&source=structured_search_input_header&search_type=filter_change' target='_blank'>Air B&B</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AccomCards